import React from 'react'
import Header from '../components/header'

export default function NotFoundPage() {
  return (
    <div>
      <Header />
      Page Not Found
    </div>
  )
}